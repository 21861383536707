import axios from "axios";

const api = axios.create({
  baseURL: "https://backend.sigmadeltaalpha.com", // Your backend API URL , the localhost is http://localhost:8000 production is https://backend.sigmadeltaalpha.com
});

// Intercept requests
api.interceptors.request.use(
  (config) => {
    // Add Authorization header only if access_token is present
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercept responses
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refresh_token");
      if (refreshToken) {
        try {
          const response = await axios.post(
            "https://backend.sigmadeltaalpha.com/auth/api/token/refresh/", // the localhost is http://localhost:8000/auth/api/token/refresh/ production is https://backend.sigmadeltaalpha.com/auth/api/token/refresh/
            { refresh: refreshToken }
          );
          const { access } = response.data;
          localStorage.setItem("access_token", access);
          originalRequest.headers["Authorization"] = `Bearer ${access}`;
          return api(originalRequest);
        } catch (refreshError) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          window.location.href = "/login";
          return Promise.reject(refreshError);
        }
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export const login = (username: string, password: string) => {
  return api.post("/auth/api/token/", { username, password });
};

export default api;
