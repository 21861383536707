import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createMember } from "../../actions/members";
import { RootState, AppDispatch } from "../../store";
import Navbar from "../../components/NavBar";

import headerImg from "../../assets/Images/sda_header_logo-removebg-preview.png";
import { useNavigate } from "react-router-dom";

import SnackbarComponent, { SnackbarMessage } from "../../components/Snackbar";

const initialFormData = {
  full_name: "",
  email: "",
  date_of_birth: "",
  chapter: "",
  member_class: "",
  member_status: "",
  national_board_active_member: false,
  omega_status: false,
  og_founding_father: false,
};

const CreateMemberForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);

  const dispatch = useDispatch<AppDispatch>();
  const { status, error } = useSelector((state: RootState) => state.members);

  const [snackbarMessage, setSnackbarMessage] =
    useState<SnackbarMessage | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const formattedData = {
      ...formData,
      date_of_birth: new Date(formData.date_of_birth)
        .toISOString()
        .split("T")[0],
    };

    dispatch(createMember(formattedData)).then((response) => {
      if (response.type.includes("fulfilled")) {
        setSnackbarMessage({
          message: "Carousel post created successfully!",
          severity: "success",
        });
        setFormData(initialFormData); // Clear the form after successful submission
      } else {
        setSnackbarMessage({
          message: "Error creating carousel post",
          severity: "error",
        });
      }
    });
  };

  return (
    <div>
      <img
        src={headerImg}
        className="w-52"
        alt="Header Logo"
        onClick={() => navigate(`/`)}
      />
      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg space-y-4"
      >
        <h2 className="text-2xl font-semibold mb-4">Create Member</h2>
        <div>
          <label className="block text-gray-700">Member Full Name</label>
          <input
            type="text"
            name="full_name"
            value={formData.full_name}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Email Address</label>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Date Of Birth</label>
          <input
            type="date"
            name="date_of_birth"
            value={formData.date_of_birth}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Member Chapter</label>
          <input
            type="text"
            name="chapter"
            value={formData.chapter}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Member Class</label>
          <input
            type="text"
            name="member_class"
            value={formData.member_class}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Member Status</label>
          <input
            type="text"
            name="member_status"
            value={formData.member_status}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="national_board_active_member"
            checked={formData.national_board_active_member}
            onChange={handleChange}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label className="ml-2 block text-gray-700">
            National Board Status
          </label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="omega_status"
            checked={formData.omega_status}
            onChange={handleChange}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label className="ml-2 block text-gray-700">Omega Chapter?</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="og_founding_father"
            checked={formData.og_founding_father}
            onChange={handleChange}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label className="ml-2 block text-gray-700">
            OG Founding Father?
          </label>
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create Member
        </button>
        {status === "pending" && (
          <p className="text-yellow-500">Submitting...</p>
        )}
        {status === "failed" && (
          <p className="text-red-500">
            Error: {typeof error === "object" ? JSON.stringify(error) : error}
          </p>
        )}
      </form>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />
    </div>
  );
};

export default CreateMemberForm;
