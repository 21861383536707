import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getNbMembers } from "../../actions/nbmembers";

import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";

import nbBnrImg from "../../assets/Images/national_leadership_banner.png";
import NationalBoardMemberCard from "../../components/NationalBoardMemberCard";

const NationalBoardPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { nbMembers, status, error } = useAppSelector(
    (state) => state.nbmembers
  );

  useEffect(() => {
    dispatch(getNbMembers());
  }, [dispatch]);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <img
        src={nbBnrImg}
        className="w-full h-32 md:h-48 p-2 rounded-lg object-cover"
        alt="National Leadership Banner"
      />
      <div className="flex flex-col justify-center items-center px-4 py-6 md:py-12 font-oswald">
        <h1 className="font-bold text-2xl md:text-3xl mb-4 text-center">
          Our Governing Board
        </h1>
        <p className="w-full md:w-3/4 text-base md:text-lg text-center">
          The national leadership of Sigma Delta Alpha is dedicated to upholding
          the core values and principles that have guided us since our founding.
          Comprising experienced and committed individuals, our leadership team
          works tirelessly to ensure Sigma Delta Alpha's mission is advanced on
          a national scale. They oversee the implementation of strategic
          initiatives, manage national events, and provide support to local
          chapters, ensuring consistency and excellence across the board. Their
          efforts are focused on fostering brotherhood, academic achievement,
          and personal development among all members. Through their vision and
          dedication, the national leadership continues to inspire and lead
          Sigma Delta Alpha towards greater success and influence, while
          maintaining the integrity and traditions that define our brotherhood.
        </p>
      </div>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 p-4 md:p-8">
          {nbMembers
            .filter((nbMember) => nbMember !== null)
            .map((nbMember) => (
              <NationalBoardMemberCard key={nbMember.id} nbMember={nbMember} />
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NationalBoardPage;
