import React from "react";
import { Link } from "react-router-dom";
import noImgAvail from "../../assets/Images/noImgAvail.png";

interface EventPostCardProps {
  events: {
    id: number | string;
    event_title: string;
    event_start_date: string | Date;
    event_end_date: string | Date;
    event_body: string;
    event_image: string;
    event_to_display: boolean | string;
  };
}

const EventPostCard: React.FC<EventPostCardProps> = ({ events }) => {
  const formatDate = (date: string | Date): string => {
    const d = typeof date === "string" ? new Date(date) : date;
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const truncateText = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <div className="flex flex-col bg-white p-4 my-2">
      <h5 className="text-lg font-semibold">{events.event_title}</h5>
      <h5 className="text-sm text-gray-500 mb-1">
        {formatDate(events.event_start_date)}
      </h5>
      <Link
        to={`/event-post/${events.id}`}
        className="text-blue-500 hover:underline text-sm mt-2"
      >
        Read More
      </Link>
    </div>
  );
};

export default EventPostCard;
