import { useNavigate } from "react-router-dom";
import AdminNbMemberTable from "../../components/AdminNbMemberTable";

export default function ManageNbMembers() {
  const navigate = useNavigate();

  return (
    <div className="p-6">
      <div className="flex justify-end mb-4">
        <button
          className="bg-blue-600 text-white font-semibold text-sm rounded-full px-6 py-2 shadow-lg hover:bg-blue-700 transition duration-300"
          onClick={() => navigate("/create-nb-member")}
        >
          Create National Board Member
        </button>
      </div>
      <AdminNbMemberTable />
    </div>
  );
}
