import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  clearNbMembers,
  createNbMember,
  deleteNbMember,
  editNbMember,
  getNbMembers,
} from "../actions/nbmembers";
import { NB_MEMBER } from "../@types";

interface NB_MEMBERS_INITIAL_STATE {
  nbMembers: NB_MEMBER[];
  status: "idle" | "pending" | "succeeded" | "failed";
  error: any;
}

const initialState: NB_MEMBERS_INITIAL_STATE = {
  nbMembers: [],
  status: "idle",
  error: null,
};

const nbMembersSlice = createSlice({
  name: "nbMembers",
  initialState,
  reducers: {},

  extraReducers(builder: ActionReducerMapBuilder<NB_MEMBERS_INITIAL_STATE>) {
    builder.addCase(getNbMembers.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action.error.message,
    }));
    builder.addCase(getNbMembers.fulfilled, (_state, action) => ({
      nbMembers: action.payload,
      error: null,
      status: "succeeded",
    }));
    builder.addCase(createNbMember.fulfilled, (state, action) => {
      const { nbMember } = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.nbMembers));
      newArr.push(nbMember);
      state.nbMembers = newArr;
    });
    builder.addCase(createNbMember.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || action.error.message;
    });
    builder.addCase(deleteNbMember.fulfilled, (state, action) => {
      const nbMemberId = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.nbMembers));
      const nbMemberIdx = state.nbMembers.find((p) => p.id === nbMemberId);
      newArr.splice(nbMemberIdx, 1);
      state.nbMembers = newArr;
    });
    builder.addCase(clearNbMembers.fulfilled, () => ({ ...initialState }));
    builder.addCase(editNbMember.fulfilled, (state, action) => {
      const nbMemberToEdit: NB_MEMBER = action.payload as unknown as NB_MEMBER;
      const newArr = JSON.parse(
        JSON.stringify(state.nbMembers)
      ) as unknown as Array<NB_MEMBER>;
      const nbMemberIdx = state.nbMembers.findIndex(
        (p) => p.id === nbMemberToEdit.id
      );
      newArr[nbMemberIdx] = nbMemberToEdit;
      state.nbMembers = newArr;
    });
  },
});

export default nbMembersSlice.reducer;
