import React from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";

import pageBnnr from "../../assets/Images/alumni_banner.png";
import headerImg from "../../assets/Images/AlumniHeaderImageV2.png";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";

import CompanyCarousel from "../../components/CompanyCarousel";

const AlumniPage: React.FC = () => {
  return (
    <div>
      <Navbar />
      <img
        src={pageBnnr}
        className="w-full h-32 md:h-44 rounded-lg shadow-md pb-2 md:pb-4"
        alt="Alumni Banner"
      />

      {/* Image and Text Card */}
      <div className="relative w-full h-60 md:h-[400px]">
        <img
          src={headerImg}
          className="w-full h-full object-cover"
          alt="Alumni Header"
        />
        <div className="absolute bottom-10 md:bottom-20 left-0 p-4 bg-opacity-50 bg-black text-white">
          <h1 className="text-xl md:text-3xl font-bold font-oswald">
            Once a Sigma, Forever a Sigma.
          </h1>
          <p className="text-sm md:text-lg font-oswald italic">
            The bonds of brotherhood don't end when you graduate. They only get
            stronger.
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center px-4 py-12 md:p-28 space-y-6 font-merriweather">
        <h1 className="font-bold font-oswald text-2xl md:text-4xl">
          The Alumni Association
        </h1>
        <p className="text-base md:text-lg text-center md:text-left">
          Our Alumni Association aims to unite alumni in a collective effort to
          support our fraternity on a national level. By organizing regular
          meetings and encouraging monthly donations, we strive to host more
          events, cover essential expenses such as insurance and fraternity
          dues, and eventually establish scholarships for active members who
          demonstrate exceptional academic and leadership qualities. Unlike
          traditional alumni chapters, our association focuses on creating a
          supportive network that drives the fraternity's growth and success.
          Together, we can ensure the continued prosperity and strength of our
          brotherhood.
        </p>
        {/* Taglines */}
        <div className="flex flex-col md:flex-row items-center md:mr-28 space-y-8 md:space-y-0 md:space-x-8">
          {/* tagline 1 */}
          <div className="flex flex-col items-center text-center">
            <PeopleAltOutlinedIcon
              sx={{ fontSize: 50, md: { fontSize: 60 } }}
            />
            <h1 className="font-bold text-lg md:text-xl">Sustaining Support</h1>
            <p className="w-3/4 md:w-1/2">
              Monthly donations from alumni help sustain our fraternity by
              funding national events, covering essential expenses like
              insurance, and ensuring the smooth operation of our organization.
            </p>
          </div>
          {/* tagline 2 */}
          <div className="flex flex-col items-center text-center">
            <PaidOutlinedIcon sx={{ fontSize: 50, md: { fontSize: 60 } }} />
            <h1 className="font-bold text-lg md:text-xl">Event Sponsorship</h1>
            <p className="w-3/4 md:w-1/2">
              Your contributions enable us to organize and host more events on a
              national level, fostering a stronger sense of community and
              brotherhood among all members.
            </p>
          </div>
          {/* tagline 3 */}
          <div className="flex flex-col items-center text-center">
            <SchoolOutlinedIcon sx={{ fontSize: 50, md: { fontSize: 60 } }} />
            <h1 className="font-bold text-lg md:text-xl">Scholarship Fund</h1>
            <p className="w-3/4 md:w-1/2">
              By donating, you help establish scholarships for active fraternity
              members who excel in academics and leadership, supporting their
              journey to success.
            </p>
          </div>
        </div>
        <div className="flex justify-center md:justify-start mt-4">
          <a
            href="https://checkout.square.site/merchant/MLJ0X7XBKM4R5/checkout/ZPO7XWSZR5LIOVHK444CKM7C"
            className="text-white bg-black py-2 px-4 rounded-lg shadow-md hover:bg-gray-800"
          >
            Join Alumni Association
          </a>
        </div>
      </div>
      <CompanyCarousel />
      <Footer />
    </div>
  );
};

export default AlumniPage;
