import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apiUrl from "../constants/apiUrl";
import { CREATE_EVENT_POST_FORM, EVENT_POST } from "../@types";
import api from "../constants/api";

const baseApiUrl = `${apiUrl}/events-post/`;

// export const GET_EVENT_POSTS = "GET_EVENT_POSTS";
// export const getEventPosts = createAsyncThunk(GET_EVENT_POSTS, async () => {
//   const res: AxiosResponse = await axios.get(baseApiUrl);
//   return res.data;
// });

export const GET_EVENT_POSTS = "GET_EVENT_POSTS";
export const getEventPosts = createAsyncThunk(
  GET_EVENT_POSTS,
  async (_, { rejectWithValue }) => {
    try {
      const res: AxiosResponse = await axios.get(baseApiUrl);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const CREATE_EVENT_POST = "CREATE_EVENT_POST";
export const createEventPost = createAsyncThunk(
  CREATE_EVENT_POST,
  async (eventPostData: CREATE_EVENT_POST_FORM, { rejectWithValue }) => {
    try {
      const res: AxiosResponse = await api.post(baseApiUrl, eventPostData);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const DELETE_EVENT_POST = "DELETE_EVENT_POST";
export const deleteEventPost = createAsyncThunk(
  DELETE_EVENT_POST,
  async (eventPostId: number | string, { rejectWithValue }) => {
    try {
      await api.delete(`${baseApiUrl}${eventPostId}/`);
      return eventPostId;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EDIT_EVENT_POST = "EDIT_EVENT_POST";
export const editEventPost = createAsyncThunk(
  EDIT_EVENT_POST,
  async (eventPostToEdit: EVENT_POST, { rejectWithValue }) => {
    try {
      const { id, ...eventPostDataToSend } = eventPostToEdit;
      await api.put(`${baseApiUrl}${id}/`, eventPostDataToSend);
      return eventPostToEdit;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const GET_EVENT_POST = "GET_EVENT_POST";
export const getEventPost = createAsyncThunk(
  GET_EVENT_POST,
  async (id: string | number, { rejectWithValue }) => {
    try {
      const res: AxiosResponse = await axios.get(`${baseApiUrl}${id}/`);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const clearEventPosts = createAsyncThunk(
  "CLEAR_EVENT_POSTS",
  async () => null
);
