import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNbMember } from "../../actions/nbmembers";
import { RootState, AppDispatch } from "../../store";
import AWS from "aws-sdk";
import Navbar from "../../components/NavBar";

import headerImg from "../../assets/Images/sda_header_logo-removebg-preview.png";
import { useNavigate } from "react-router-dom";

import SnackbarComponent, { SnackbarMessage } from "../../components/Snackbar";

const initialFormData = {
  member_name: "",
  position_name: "",
  email_address: "",
  chapter_member: "",
  class_member: "",
  member_pic: null as File | null,
};

// Configure AWS SDK
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3();

const CreateNbMemberForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);

  const dispatch = useDispatch<AppDispatch>();
  const { status, error } = useSelector((state: RootState) => state.nbmembers);

  const [snackbarMessage, setSnackbarMessage] =
    useState<SnackbarMessage | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    if (name === "member_pic" && files) {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const uploadImageToS3 = async (file: File) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME || "",
      Key: `member_pic/${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
    };

    try {
      const { Location } = await s3.upload(params).promise();
      return Location;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let imageUrl = "";
    if (formData.member_pic) {
      imageUrl = await uploadImageToS3(formData.member_pic);
    }

    const formattedData = {
      ...formData,
      member_pic: imageUrl,
    };
    dispatch(createNbMember(formattedData)).then((response) => {
      if (response.type.includes("fulfilled")) {
        setSnackbarMessage({
          message: "Carousel post created successfully!",
          severity: "success",
        });
        setFormData(initialFormData); // Clear the form after successful submission
      } else {
        setSnackbarMessage({
          message: "Error creating carousel post",
          severity: "error",
        });
      }
    });
  };

  return (
    <div>
      <img
        src={headerImg}
        className="w-52"
        alt="Header Logo"
        onClick={() => navigate(`/`)}
      />
      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg space-y-4"
      >
        <h2 className="text-2xl font-semibold mb-4">
          Create National Board Member
        </h2>
        <div>
          <label className="block text-gray-700">Member Name</label>
          <input
            type="text"
            name="member_name"
            value={formData.member_name}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Position</label>
          <input
            type="text"
            name="position_name"
            value={formData.position_name}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Email Address</label>
          <input
            type="text"
            name="email_address"
            value={formData.email_address}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Chapter</label>
          <input
            type="text"
            name="chapter_member"
            value={formData.chapter_member}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Class</label>
          <input
            type="text"
            name="class_member"
            value={formData.class_member}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Member Image</label>
          <input
            type="file"
            name="member_pic"
            accept="image/*"
            onChange={handleChange}
            className="mt-1 block w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create National Board Member
        </button>
        {status === "pending" && (
          <p className="text-yellow-500">Submitting...</p>
        )}
        {status === "failed" && (
          <p className="text-red-500">
            Error: {typeof error === "object" ? JSON.stringify(error) : error}
          </p>
        )}
      </form>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />
    </div>
  );
};

export default CreateNbMemberForm;
