import React, { useState } from "react";
import headerImgV2 from "../../assets/Images/sda_header_logo-removebg-preview.png";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="text-black shadow-lg">
      <div className="flex items-center justify-between mx-auto p-4">
        <a href="/" className="flex items-center">
          <img src={headerImgV2} className="h-24 w-full" alt="Flowbite Logo" />
        </a>
        <button
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-dropdown"
          aria-expanded={menuOpen ? "true" : "false"}
          onClick={handleMenuToggle}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={
                menuOpen
                  ? "M6 18L18 6M6 6l12 12" // X icon when menu is open
                  : "M4 6h16M4 12h16M4 18h16" // Hamburger icon when menu is closed
              }
            />
          </svg>
        </button>
        <div
          className={`${
            menuOpen ? "block" : "hidden"
          } w-full md:block md:w-auto ml-auto`}
          id="navbar-dropdown"
        >
          <ul className="flex flex-col font-oswald text-lg tracking-wide font-bold p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
            <li>
              <a href="/" className="text-black block px-4 py-2 md:p-0">
                Home
              </a>
            </li>
            <li className="relative">
              <button
                onClick={handleDropdownToggle}
                className="text-black flex items-center w-full px-4 py-2 md:p-0"
              >
                About
                <svg
                  className="w-2.5 h-2.5 ml-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              {/* Dropdown menu */}
              <div
                className={`${
                  dropdownOpen ? "block" : "hidden"
                } md:absolute md:top-full mt-1 md:right-0 z-10 w-full md:w-44  divide-y divide-gray-100 rounded-lg shadow bg-gray-700 dark:divide-gray-600`}
                id="dropdownNavbar"
              >
                <ul className="py-2 text-sm text-gray-200 dark:text-gray-200">
                  <li>
                    <a
                      href="/our-story"
                      className="block px-4 py-2 hover:bg-gray-600 hover:text-white"
                    >
                      Our Story
                    </a>
                  </li>
                  <li>
                    <a
                      href="/alumni"
                      className="block px-4 py-2 hover:bg-gray-600 hover:text-white"
                    >
                      Alumni
                    </a>
                  </li>
                  <li>
                    <a
                      href="/chapters"
                      className="block px-4 py-2 hover:bg-gray-600 hover:text-white"
                    >
                      Chapters
                    </a>
                  </li>
                  <li>
                    <a
                      href="/national-board"
                      className="block px-4 py-2 hover:bg-gray-600 hover:text-white"
                    >
                      National Leadership
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a
                href="/news-and-events"
                className="text-black block px-4 py-2 md:p-0"
              >
                News & Events
              </a>
            </li>
            <li>
              <a href="/contact" className="text-black block px-4 py-2 md:p-0">
                Contact
              </a>
            </li>
            <li>
              <a
                href="/admin"
                className="text-white bg-[#800000] py-2 px-3 rounded-xl text-sm block md:inline-block"
              >
                SDA Portal
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
