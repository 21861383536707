import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  clearChapters,
  createChapter,
  deleteChapter,
  editChapter,
  getChapters,
} from "../actions/chapters";
import { CHAPTER } from "../@types";

interface CHAPTERS_INITIAL_STATE {
  chapters: CHAPTER[];
  status: "idle" | "pending" | "succeeded" | "failed";
  error: any;
}

const initialState: CHAPTERS_INITIAL_STATE = {
  chapters: [],
  status: "idle",
  error: null,
};

const chaptersSlice = createSlice({
  name: "chapters",
  initialState,
  reducers: {},

  extraReducers(builder: ActionReducerMapBuilder<CHAPTERS_INITIAL_STATE>) {
    builder.addCase(getChapters.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action.error.message,
    }));
    builder.addCase(getChapters.fulfilled, (_state, action) => ({
      chapters: action.payload,
      error: null,
      status: "succeeded",
    }));
    builder.addCase(createChapter.fulfilled, (state, action) => {
      const { chapter } = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.chapters));
      newArr.push(chapter);
      state.chapters = newArr;
    });
    builder.addCase(createChapter.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload || action.error.message; // Store the error message in the state for further use
    });
    builder.addCase(deleteChapter.fulfilled, (state, action) => {
      const chapterId = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.chapters));
      const chapterIdx = state.chapters.find((p) => p.id === chapterId);
      newArr.splice(chapterIdx, 1);
      state.chapters = newArr;
    });
    builder.addCase(clearChapters.fulfilled, () => ({ ...initialState }));
    // builder.addCase(editChapter.fulfilled, (state, action) => {
    //     const chapterToEdit: CHAPTER = action.payload as unknown as CHAPTER;
    //     const newArr = JSON.parse(
    //         JSON.stringify(state.chapters)
    //     ) as unknown as Array<CHAPTER>;
    //     const chapterIdx = state.chapters.findIndex(
    //         (p) => p.id === chapterToEdit.id
    //     );
    //     newArr[chapterIdx] = chapterToEdit;
    //     state.chapters = newArr
    // })
    builder.addCase(editChapter.fulfilled, (state, action) => {
      const chapterToEdit: CHAPTER = action.payload as unknown as CHAPTER;
      const newArr = JSON.parse(
        JSON.stringify(state.chapters)
      ) as unknown as Array<CHAPTER>;
      const chapterIdx = state.chapters.findIndex(
        (p) => p.id === chapterToEdit.id
      );
      if (chapterIdx !== -1) {
        newArr[chapterIdx] = chapterToEdit;
        state.chapters = newArr;
      }
    });
  },
});

export default chaptersSlice.reducer;
