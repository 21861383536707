import { FunctionComponent } from "react";

const PageNotFound: FunctionComponent = () => {
  return (
    <div className="flex justify-center items-center text-lg font-bold text-black">
      404 Page Not Found or Doesn't Exist Anymore
    </div>
  );
};

export default PageNotFound;
