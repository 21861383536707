import React from "react";
import noImgAvail from "../../assets/Images/noImgAvail.png";
import { Link } from "react-router-dom";

interface NewsPostCardProps {
  news: {
    id: number | string;
    news_title: string;
    news_publish_date: string | Date;
    news_body: string;
    news_image: string;
    news_to_display: boolean;
  };
}

const NewsPostCard: React.FC<NewsPostCardProps> = ({ news }) => {
  const formatDate = (date: string | Date): string => {
    const d = typeof date === "string" ? new Date(date) : date;
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const truncateText = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <div className="flex flex-col bg-gray-100 border rounded-lg shadow-lg m-4 w-[600px]">
      <div className="flex flex-row">
        <img
          className="w-52 h-52 object-cover rounded-l-lg"
          src={news.news_image || noImgAvail}
          alt={news.news_title}
        />
        <div className="flex flex-col p-4">
          <h5 className="text-lg font-bold">{news.news_title}</h5>
          <h5 className="text-xs text-gray-500 mb-2">
            {formatDate(news.news_publish_date)}
          </h5>
          <p className="text-sm text-gray-700">
            {truncateText(news.news_body, 60)}
          </p>
          <Link
            to={`/news-post/${news.id}`}
            className="text-blue-500 hover:underline mt-2"
          >
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewsPostCard;
