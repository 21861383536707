// import { configureStore, PreloadedState } from "@reduxjs/toolkit";
// import thunk from "redux-thunk";
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";
// import storage from "redux-persist/lib/storage";

// import rootReducer from "./reducers";

// const persistConfig = {
//   key: "root",
//   version: 1,
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware: any) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }).prepend(thunk),
// });

// export function setupStore(preloadedState?: PreloadedState<RootState>) {
//   return configureStore({
//     reducer: rootReducer,
//     preloadedState,
//   });
// }

// export const persistor = persistStore(store);

// export type RootState = ReturnType<typeof store.getState>;
// export type AppStore = ReturnType<typeof setupStore>;

// export type AppDispatch = typeof store.dispatch;

// export default store;

////////////////////////////

// import { configureStore } from "@reduxjs/toolkit";
// import {thunk} from "redux-thunk";
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import rootReducer from "./reducers";

// // Manually define the type for the preloaded state
// type PreloadedState<T> = Partial<T>;

// const persistConfig = {
//   key: "root",
//   version: 1,
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware: any) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }).prepend(thunk),
// });

// export function setupStore(preloadedState?: PreloadedState<RootState>) {
//   return configureStore({
//     reducer: rootReducer,
//     preloadedState,
//   });
// }

// export const persistor = persistStore(store);

// export type RootState = ReturnType<typeof store.getState>;
// export type AppStore = ReturnType<typeof setupStore>;
// export type AppDispatch = typeof store.dispatch;

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export default store;
