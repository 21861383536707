import React from "react";
import {
  FaLinkedin,
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaFacebook,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-[#FAF5EF] py-8 font-oswald">
      <div className=" mx-auto px-4">
        <div className="flex flex-col md:flex-row md:justify-between items-center border-t border-gray-300 pt-4">
          <div className="text-center md:text-left">
            <p className="text-black">
              &copy; 2023 All rights reserved. Sigma Delta Alpha
            </p>
          </div>
          <div className="flex space-x-4 mt-4 md:mt-0">
            <a
              href="https://checkout.square.site/merchant/MLJ0X7XBKM4R5/checkout/ZPO7XWSZR5LIOVHK444CKM7C"
              className="bg-black text-white py-2 px-4 rounded-xl"
            >
              Donate
            </a>
            <a
              href="/admin"
              className="bg-black text-white py-2 px-4 rounded-xl"
            >
              SDA Portal
            </a>
          </div>
        </div>
        <div className="flex justify-center space-x-4 mt-4">
          <a href="#" aria-label="LinkedIn" className="text-black">
            <FaLinkedin size={24} />
          </a>
          <a href="#" aria-label="YouTube" className="text-black">
            <FaYoutube size={24} />
          </a>
          <a href="#" aria-label="Twitter" className="text-black">
            <FaTwitter size={24} />
          </a>
          <a href="#" aria-label="Instagram" className="text-black">
            <FaInstagram size={24} />
          </a>
          <a href="#" aria-label="Facebook" className="text-black">
            <FaFacebook size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
