import { useNavigate } from "react-router-dom";
import AdminMemberTable from "../../components/AdminMemberTable";

export default function ManageMembers() {
  const navigate = useNavigate();
  return (
    <div className="p-6">
      <div className="flex justify-end mb-4">
        <button
          className="bg-blue-600 text-white font-semibold text-sm rounded-full px-6 py-2 shadow-lg hover:bg-blue-700 transition duration-300"
          onClick={() => navigate("/create-member")}
        >
          Create Member
        </button>
      </div>
      <AdminMemberTable />
    </div>
  );
}
