import React, { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";

import LoginPage from "./LoginPage";
import headerImg from "../../assets/Images/sda_header_logo-removebg-preview.png";
import AdminNavBar from "../../components/AdminNavBar";

const Admin: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    navigate("/admin");
  };

  const handleSignOut = () => {
    dispatch(logout() as any); // TypeScript workaround
    navigate("/");
  };

  if (!isAuthenticated) {
    return <LoginPage onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <div className="flex flex-col">
      <img
        src={headerImg}
        className="w-52"
        alt="Header Logo"
        onClick={() => navigate(`/`)}
      />

      <div className="flex flex-row">
        <AdminNavBar onSignOut={handleSignOut} />
        <Outlet />
      </div>
    </div>
  );
};

export default Admin;
