import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  clearNewsPosts,
  createNewsPost,
  deleteNewsPost,
  editNewsPost,
  getNewsPosts,
} from "../actions/news";
import { NEWS_POST } from "../@types";

interface NEWS_POSTS_INITIAL_STATE {
  newsPosts: Array<NEWS_POST>;
  status: "idle" | "pending" | "succeeded" | "failed";
  error: any;
}

const initialState: NEWS_POSTS_INITIAL_STATE = {
  newsPosts: [],
  status: "idle",
  error: null,
};

const newsPostsSlice = createSlice({
  name: "newsPosts",
  initialState,
  reducers: {},

  extraReducers(builder: ActionReducerMapBuilder<NEWS_POSTS_INITIAL_STATE>) {
    builder.addCase(getNewsPosts.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action,
    }));
    builder.addCase(getNewsPosts.fulfilled, (_state, action) => ({
      newsPosts: action.payload,
      error: null,
      status: "succeeded",
    }));
    builder.addCase(createNewsPost.fulfilled, (state, action) => {
      const { newsPost } = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.newsPosts));
      newArr.push(newsPost);
      state.newsPosts = newArr;
    });
    builder.addCase(createNewsPost.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload; // Store the error message in the state for further use
    });
    builder.addCase(deleteNewsPost.fulfilled, (state, action) => {
      const newsPostId = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.newsPosts));
      const newsPostIdx = state.newsPosts.find((p) => p.id === newsPostId);
      newArr.splice(newsPostIdx, 1);
      state.newsPosts = newArr;
    });
    builder.addCase(clearNewsPosts.fulfilled, () => ({ ...initialState }));
    builder.addCase(editNewsPost.fulfilled, (state, action) => {
      const newsPostToEdit: NEWS_POST = action.payload as unknown as NEWS_POST;
      const newArr = JSON.parse(
        JSON.stringify(state.newsPosts)
      ) as unknown as Array<NEWS_POST>;
      const newsPostIdx = state.newsPosts.findIndex(
        (p) => p.id === newsPostToEdit.id
      );
      newArr[newsPostIdx] = newsPostToEdit;
      state.newsPosts = newArr;
    });
  },
});

export default newsPostsSlice.reducer;
