import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createChapter } from "../../actions/chapters";
import { RootState, AppDispatch } from "../../store";
import AWS from "aws-sdk";
import Navbar from "../../components/NavBar";

import headerImg from "../../assets/Images/sda_header_logo-removebg-preview.png";
import { useNavigate } from "react-router-dom";

import SnackbarComponent, { SnackbarMessage } from "../../components/Snackbar";

// Configure AWS SDK
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3();

const initialFormData = {
  chapter_name: "",
  chapter_sign: "",
  chapter_founding_date: "",
  school_website: "",
  chapter_status: "",
  school_logo: null as File | null,
};

const CreateChapterForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);

  const dispatch = useDispatch<AppDispatch>();
  const { status, error } = useSelector((state: RootState) => state.chapters);

  const [snackbarMessage, setSnackbarMessage] =
    useState<SnackbarMessage | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    if (name === "school_logo" && files) {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const uploadImageToS3 = async (file: File) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME || "",
      Key: `school_logos/${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
    };

    try {
      const { Location } = await s3.upload(params).promise();
      return Location;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let imageUrl = "";
    if (formData.school_logo) {
      imageUrl = await uploadImageToS3(formData.school_logo);
    }

    const formattedData = {
      ...formData,
      school_logo: imageUrl,
      chapter_founding_date: new Date(formData.chapter_founding_date)
        .toISOString()
        .split("T")[0], // Convert date to YYYY-MM-DD
    };

    dispatch(createChapter(formattedData)).then((response) => {
      if (response.type.includes("fulfilled")) {
        setSnackbarMessage({
          message: "Carousel post created successfully!",
          severity: "success",
        });
        setFormData(initialFormData); // Clear the form after successful submission
      } else {
        setSnackbarMessage({
          message: "Error creating carousel post",
          severity: "error",
        });
      }
    });
  };

  return (
    <div>
      <img
        src={headerImg}
        className="w-52"
        alt="Header Logo"
        onClick={() => navigate(`/`)}
      />

      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg space-y-4"
      >
        <h2 className="text-2xl font-semibold mb-4">Create Chapter</h2>
        <div>
          <label className="block text-gray-700">Chapter Name</label>
          <input
            type="text"
            name="chapter_name"
            value={formData.chapter_name}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Chapter Sign</label>
          <input
            type="text"
            name="chapter_sign"
            value={formData.chapter_sign}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Chapter Founding Date</label>
          <input
            type="date"
            name="chapter_founding_date"
            value={formData.chapter_founding_date}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">School Website</label>
          <input
            type="text"
            name="school_website"
            value={formData.school_website}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">School Status</label>
          <input
            type="text"
            name="chapter_status"
            value={formData.chapter_status}
            onChange={handleChange}
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">School Logo</label>
          <input
            type="file"
            name="school_logo"
            accept="image/*"
            onChange={handleChange}
            className="mt-1 block w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create Chapter
        </button>
        {status === "pending" && (
          <p className="text-yellow-500">Submitting...</p>
        )}
        {status === "failed" && (
          <p className="text-red-500">
            Error: {typeof error === "object" ? JSON.stringify(error) : error}
          </p>
        )}
      </form>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />
    </div>
  );
};

export default CreateChapterForm;
