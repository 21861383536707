import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getChapters } from "../../actions/chapters";
import Navbar from "../../components/NavBar";

import chaptersBnr from "../../assets/Images/chapters_banners.png";

import ChapterCard from "../../components/ChapterCards";
import Footer from "../../components/Footer";

const ChaptersPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { chapters, status, error } = useAppSelector((state) => state.chapters);

  useEffect(() => {
    dispatch(getChapters());
  }, [dispatch]);

  if (status === "pending") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <img
        src={chaptersBnr}
        alt="Chapters Banner"
        className="h-28 md:h-36 w-full rounded-lg p-2 object-cover"
      />
      <div className="flex flex-col justify-center items-center px-4 py-6 md:py-12 space-y-4 md:space-y-6">
        <h1 className="font-bold font-oswald text-xl md:text-2xl text-center">
          Our Established Chapters
        </h1>
        <p className="font-oswald text-base md:text-xl text-center">
          Sigma Delta Alpha proudly extends its brotherhood to 12 chapters
          across universities in the United States. Each chapter upholds our
          core values of brotherhood, leadership, and community service,
          creating a strong network of support and camaraderie. Our chapters are
          not just a representation of our fraternity; they are active
          communities dedicated to making a positive impact on their campuses
          and beyond.
        </p>
      </div>
      <div className="p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {chapters
          .filter((chapter) => chapter !== null)
          .map((chapter) => (
            <ChapterCard key={chapter.id} chapter={chapter} />
          ))}
      </div>
      <Footer />
    </div>
  );
};

export default ChaptersPage;
