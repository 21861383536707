import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";

import { editNewsPost, getNewsPost } from "../../actions/news";
import { NEWS_POST, CREATE_NEWS_POST_FORM } from "../../@types";

interface EditNewsPostFormProps {
  newsId: string | number;
  onClose: () => void;
}

const EditNewsPostForm: React.FC<EditNewsPostFormProps> = ({
  newsId,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { newsPosts } = useSelector((state: RootState) => state.news);
  const [formData, setFormData] = useState<
    CREATE_NEWS_POST_FORM & { id: number | string }
  >({
    id: newsId,
    news_title: "",
    news_publish_date: "",
    news_body: "",
    news_image: "",
    news_to_display: false,
  });

  useEffect(() => {
    dispatch(getNewsPost(newsId));
  }, [dispatch, newsId]);

  useEffect(() => {
    const currentNews = newsPosts.find((news: NEWS_POST) => news.id === newsId);
    if (currentNews) {
      setFormData({
        id: newsId,
        news_title: currentNews.news_title || "",
        news_publish_date: currentNews.news_publish_date || "",
        news_body: currentNews.news_body || "",
        news_image: currentNews.news_image || "",
        news_to_display: currentNews.news_to_display || false,
      });
    }
  }, [newsPosts, newsId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.news_title) {
      dispatch(editNewsPost(formData));
      onClose();
    } else {
      alert("Please fill in all required fields.");
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <form onSubmit={handleSubmit} className="space-y-6">
        <h2 className="text-2xl font-bold mb-4">Edit News Post</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              News Title
            </label>
            <input
              type="text"
              name="news_title"
              value={formData.news_title}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Publish Date
            </label>
            <input
              type="date"
              name="news_publish_date"
              value={formData.news_publish_date.toString()}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              News Details
            </label>
            <input
              type="text"
              name="news_body"
              value={formData.news_body}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Image
            </label>
            <input
              type="text"
              name="news_image"
              value={formData.news_image}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="news_to_display"
              checked={Boolean(formData.news_to_display)}
              onChange={handleChange}
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label className="ml-2 block text-sm font-medium text-gray-700">
              Display
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditNewsPostForm;
