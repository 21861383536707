import React from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";

import pageBnrv2 from "../../assets/Images/banner_beta.png";
import missionImg from "../../assets/Images/legacyImage.png";
import legacyImg from "../../assets/Images/missionStatement.png";
import ogffImg from "../../assets/Images/ogffmembers.png";

const names = [
  "Rogelio Bolaños",
  "Francisco Bustamante",
  "José Cortez",
  "Ralph Covarrubias",
  "Cesar Estrada",
  "Sóstenes González",
  "Héctor Gutiérrez",
  "Francisco Huerta",
  "Rudy Márquez",
  "Cesar Martínez",
  "Luis Medina",
  "Rafael Moya",
  "Miguel Orejel",
  "Claudio Pérez",
  "Victor Ramírez",
  "Ramiro Rocha",
  "José Trujillo",
  "José Villareal",
];

const OurStoryPage: React.FC = () => {
  const firstColumn = names.slice(0, 9);
  const secondColumn = names.slice(9, 18);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <img
        src={pageBnrv2}
        className="w-full rounded-lg p-2"
        alt="Our Story Banner"
      />
      {/* Content Section */}
      <div className="flex flex-col items-center px-4 md:px-6 py-8 md:py-12 space-y-12 md:space-y-20">
        {/* Legacy Section */}
        <div className="flex flex-col md:flex-row items-center justify-between gap-8 md:gap-12 w-full max-w-6xl">
          <img
            src={missionImg}
            className="w-full md:w-1/2 rounded-lg shadow-md"
            alt="Legacy"
          />
          <div className="space-y-6 text-center md:text-left">
            <h1 className="text-3xl md:text-4xl font-bold font-oswald text-gray-800">
              Our Legacy
            </h1>
            <p className="text-gray-700 font-merriweather">
              In the Fall semester of 1991, Francisco Huerta, a student at San
              Jose State University, envisioned creating an organization to
              serve the needs of Latinos on campus. He led the initial meetings
              that laid the foundation for Sigma Delta Alpha. By November 1991,
              the first introductory meeting took place, setting the
              fraternity's ideological purposes. After several meetings and
              successful events, the fraternity was officially recognized on May
              5, 1992, with 18 Founding Fathers. Sigma Delta Alpha is notable
              for being one of only two Latino fraternities founded during the
              fragmentation period from 1990 to 2000 and the third in
              California. It also distinguishes itself by eschewing the practice
              of stepping, reflecting its unique cultural history.
            </p>
            <p className="text-gray-700 font-merriweather">
              Over the decades, Sigma Delta Alpha has grown beyond its origins,
              establishing chapters across the United States. Our fraternity has
              become a beacon of leadership, cultural pride, and academic
              achievement, fostering a strong brotherhood that transcends
              generations. The impact of Sigma Delta Alpha is felt not only on
              the campuses where we are present but also in the communities we
              serve, as our members continue to uphold the values of service,
              unity, and excellence. The legacy of our Founding Fathers lives on
              through the dedication and accomplishments of every brother who
              wears our letters.
            </p>
          </div>
        </div>
        {/* Mission Statement Section */}
        <div className="flex flex-col md:flex-row items-center justify-between gap-8 md:gap-12 w-full max-w-6xl">
          <div className="space-y-6 text-center md:text-left">
            <h1 className="text-3xl md:text-4xl font-bold font-oswald text-gray-800">
              Mission Statement
            </h1>
            <p className="text-gray-700 font-merriweather">
              Sigma Delta Alpha is dedicated to fostering academic excellence,
              personal growth, and cultural enrichment within our fraternity and
              the broader community. We strive to support each other
              academically, socially, and personally, and to promote and retain
              Latinos and other underrepresented groups in higher education. By
              working collaboratively with other organizations and engaging with
              our community, we aim to uphold and celebrate the rich Latino
              culture. Through our commitment to excellence and loyalty, we
              endeavor to cast a positive light upon our fraternity, ourselves,
              and our community, exemplifying our credo: Excellentia et Fides
              Intra Fraternitus.
            </p>
            <p className="text-gray-700 font-merriweather">
              In our pursuit of these ideals, we embrace the conviction that
              true brotherhood is founded on principles of equality, respect,
              and unwavering dedication to our shared goals. The ethos of Sigma
              Delta Alpha is deeply rooted in the belief that academic success
              and cultural pride are not just personal achievements but are
              integral to the collective strength of our fraternity. By
              committing ourselves to these values, we not only honor the legacy
              of those who came before us but also inspire future generations to
              continue this mission. Our actions, guided by our Constitution,
              serve as a testament to the enduring bonds of brotherhood and the
              transformative power of our union.
            </p>
          </div>
          <img
            src={legacyImg}
            className="w-full md:w-1/2 rounded-lg shadow-md"
            alt="Mission"
          />
        </div>
        {/* Founding Father Section */}
        <div className="w-full max-w-6xl">
          <h1 className="text-3xl md:text-4xl font-bold font-oswald text-gray-800 text-center mb-6">
            Original Founding Fathers
          </h1>
          <p className="text-gray-700 text-center mb-10 font-merriweather text-lg">
            Our honorable fraternity was founded on May 5, 1992 by eighteen
            remarkable gentlemen in San José, California. They envisioned a
            Latino-based organization that would focus on helping its members
            get through college while engaging them in community service and
            cultural enrichment projects.
          </p>
          <img src={ogffImg} className="w-full mb-10" alt="Founding Fathers" />
          {/* Unordered List */}
          <div className="flex flex-col md:flex-row justify-center text-center font-merriweather font-bold text-lg md:text-xl">
            <ul className="list-disc list-inside w-full md:w-1/2 space-y-2 md:space-y-4">
              {firstColumn.map((name, index) => (
                <li key={index} className="py-1">
                  {name}
                </li>
              ))}
            </ul>
            <ul className="list-disc list-inside w-full md:w-1/2 space-y-2 md:space-y-4">
              {secondColumn.map((name, index) => (
                <li key={index} className="py-1">
                  {name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OurStoryPage;
