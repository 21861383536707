import axios, { Axios, AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apiUrl from "../constants/apiUrl";
import { CREATE_MEMBER_FORM, MEMBER } from "../@types";
import api from "../constants/api";

const baseApiUrl = `${apiUrl}/members/`;

export const GET_MEMBERS = "GET_MEMBERS";
export const getMembers = createAsyncThunk(
  GET_MEMBERS,
  async (_, { rejectWithValue }) => {
    try {
      const res: AxiosResponse = await axios.get(baseApiUrl);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const CREATE_MEMBER = "CREATE_MEMBER";
export const createMember = createAsyncThunk(
  CREATE_MEMBER,
  async (memberData: CREATE_MEMBER_FORM, { rejectWithValue }) => {
    try {
      const res = await api.post(`${baseApiUrl}`, memberData);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const DELETE_MEMBER = "DELETE_MEMBER";
export const deleteMember = createAsyncThunk(
  DELETE_MEMBER,
  async (MemberId: number | string, { rejectWithValue }) => {
    try {
      await api.delete(`${baseApiUrl}${MemberId}/`, {});
      return MemberId;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const EDIT_MEMBER = "EDIT_MEMBER";
export const editMember = createAsyncThunk(
  EDIT_MEMBER,
  async (memberToEdit: MEMBER, { rejectWithValue }) => {
    try {
      const { id, ...memberDataToSend } = memberToEdit;
      await api.put(`${baseApiUrl}${id}/`, memberDataToSend);
      return memberToEdit;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const GET_MEMBER = "GET_MEMBER";
export const getMember = createAsyncThunk(
  GET_MEMBER,
  async (id: string | number, { rejectWithValue }) => {
    try {
      const res: AxiosResponse = await axios.get(`${baseApiUrl}${id}/`);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const clearMembers = createAsyncThunk("CLEAR_MEMBERS", async () => null);
