import React from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";

import pageBnnr from "../../assets/Images/contact_banner.png";

const ContactPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <img
        src={pageBnnr}
        className="w-full h-32 md:h-44 p-2 object-cover"
        alt="Contact Page Banner"
      />
      {/* Contact Form */}
      <div className="bg-white p-4 md:p-8 rounded-xl m-4 md:m-6">
        <div>
          <h1 className="text-center text-xl md:text-2xl font-bold mb-4 font-oswald">
            Contact a Specific Chapter
          </h1>
          <h5 className="text-center mb-6 font-oswald text-sm md:text-base">
            Please fill out the form below and someone will be in touch with you
            within the next 3-5 business days.
          </h5>
          <form>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-bold mb-2 text-sm md:text-base">
                  Please Select Subject or Topic:
                </label>
                <select className="block w-full border border-gray-300 rounded py-2 px-3 text-sm md:text-base">
                  <option>General Inquiry</option>
                  <option>Membership Information</option>
                  <option>Alumni Relations</option>
                  <option>Chapter Expansion</option>
                  <option>Chapter Information</option>
                  <option>Other</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2 text-sm md:text-base">
                  Please Select School:
                </label>
                <select className="block w-full border border-gray-300 rounded py-2 px-3 text-sm md:text-base">
                  <option>Founding - San Jose State University</option>
                  <option>Alpha - USC</option>
                  <option>Beta - California State University, Fresno</option>
                  <option>Gamma - University of Nevada, Las Vegas</option>
                  <option>
                    Delta - California State University, Sacramento
                  </option>
                  <option>Epsilon - UCI</option>
                  <option>Zeta - California State University, Fullerton</option>
                  <option>Eta - UCR</option>
                  <option>Theta - UC Merced</option>
                  <option> Iota - UC Davis</option>
                  <option>Kappa - UCSB</option>
                  <option>
                    Lambda - California State Polytechnic University, Pomona
                  </option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-bold mb-2 text-sm md:text-base">
                  Name:
                </label>
                <input
                  type="text"
                  className="block w-full border border-gray-300 rounded py-2 px-3 text-sm md:text-base"
                  placeholder="Type Full Name"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2 text-sm md:text-base">
                  Email Address:
                </label>
                <input
                  type="email"
                  className="block w-full border border-gray-300 rounded py-2 px-3 text-sm md:text-base"
                  placeholder="user@domain.com"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-700 font-bold mb-2 text-sm md:text-base">
                  Phone Number:
                </label>
                <input
                  type="tel"
                  className="block w-full border border-gray-300 rounded py-2 px-3 text-sm md:text-base"
                  placeholder="(xxx) xxx-xxxx"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2 text-sm md:text-base">
                  Comments:
                </label>
                <textarea
                  className="block w-full border border-gray-300 rounded py-2 px-3 text-sm md:text-base"
                  placeholder="Please enter any questions/feedback here."
                ></textarea>
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
