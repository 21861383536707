import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { deleteNewsPost, getNewsPosts } from "../../actions/news";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNewsPostForm from "../EditNewsPostForm";

const StyledTableCellHeader = styled(TableCell)(() => ({
  backgroundColor: "#F5F5F5",
  fontFamily: "Neue Haas Grotesk",
  fontWeight: "600",
  textAlign: "center",
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  fontWeight: "500",
  textAlign: "center",
  fontFamily: "Neue Haas Grotesk",
}));

const formatDate = (date: string | Date): string => {
  if (!date) return "N/A"; // Add null check
  const d = typeof date === "string" ? new Date(date) : date;
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const year = d.getFullYear();
  return `${month}-${day}-${year}`;
};

const AdminNewsPostTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { newsPosts, status, error } = useAppSelector((state) => state.news);
  const [editNewsId, setEditNewsId] = useState<number | string | null>(null);

  useEffect(() => {
    dispatch(getNewsPosts());
  }, [dispatch]);

  const handleDelete = (id: number | string) => {
    if (window.confirm("Are you sure you want to delete this News Post?")) {
      dispatch(deleteNewsPost(id));
    }
  };

  const handleEdit = (id: number | string) => {
    setEditNewsId(id);
  };

  if (status === "pending") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    const errorMessage = error?.detail ? error.detail : error;
    return <div>Error: {errorMessage}</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="News Post Table">
        <TableHead>
          <TableRow>
            <StyledTableCellHeader>News Title</StyledTableCellHeader>
            <StyledTableCellHeader>Publish Date</StyledTableCellHeader>
            <StyledTableCellHeader>Description</StyledTableCellHeader>
            <StyledTableCellHeader>Image</StyledTableCellHeader>
            <StyledTableCellHeader>To Display</StyledTableCellHeader>
            <StyledTableCellHeader>Actions</StyledTableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {newsPosts &&
            newsPosts.map((news: any) =>
              news ? (
                <TableRow key={news.id}>
                  <StyledTableCell>{news.news_title || "N/A"}</StyledTableCell>
                  <StyledTableCell>
                    {news.news_publish_date
                      ? formatDate(news.news_publish_date)
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>{news.news_body || "N/A"}</StyledTableCell>
                  <StyledTableCell>{news.news_image || "N/A"}</StyledTableCell>
                  <StyledTableCell>
                    {news.news_to_display ? "Yes" : "No"}
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={() => handleEdit(news.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(news.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ) : (
                <TableRow key={Math.random()}>
                  <StyledTableCell colSpan={6}>No Data</StyledTableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
      {editNewsId && (
        <EditNewsPostForm
          newsId={editNewsId}
          onClose={() => setEditNewsId(null)}
        />
      )}
    </TableContainer>
  );
};

export default AdminNewsPostTable;
