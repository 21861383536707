import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  clearCarouselPosts,
  createCarouselPost,
  deleteCarouselPost,
  editCarouselPost,
  getCarouselPost,
  getCarouselPosts,
} from "../actions/carouselposts";
import { CAROUSEL_POST } from "../@types";

interface CAROUSEL_POSTS_INITIAL_STATE {
  carouselPosts: Array<CAROUSEL_POST>;
  status: "idle" | "pending" | "suceeded" | "failed" | "loading" | "succeeded";
  error: any;
}

const initialState: CAROUSEL_POSTS_INITIAL_STATE = {
  carouselPosts: [],
  status: "idle",
  error: null,
};

const carouselPostsSlice = createSlice({
  name: "carouselPosts",
  initialState,
  reducers: {},

  extraReducers(
    builder: ActionReducerMapBuilder<CAROUSEL_POSTS_INITIAL_STATE>
  ) {
    builder.addCase(getCarouselPosts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getCarouselPosts.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action,
    }));
    builder.addCase(getCarouselPosts.fulfilled, (_state, action) => ({
      carouselPosts: action.payload,
      error: null,
      status: "suceeded",
    }));
    builder.addCase(createCarouselPost.fulfilled, (state, action) => {
      const { carouselPost } = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.carouselPosts));
      newArr.push(carouselPost);
      state.carouselPosts = newArr;
    });
    builder.addCase(createCarouselPost.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(deleteCarouselPost.fulfilled, (state, action) => {
      const carouselPostId = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.carouselPosts));
      const carouselPostIdx = state.carouselPosts.find(
        (p) => p.id === carouselPostId
      );
      newArr.splice(carouselPostIdx, 1);
      state.carouselPosts = newArr;
    });
    builder.addCase(clearCarouselPosts.fulfilled, () => ({ ...initialState }));
    builder.addCase(editCarouselPost.fulfilled, (state, action) => {
      const carouselPostToEdit: CAROUSEL_POST =
        action.payload as unknown as CAROUSEL_POST;
      const newArr = JSON.parse(
        JSON.stringify(state.carouselPosts)
      ) as unknown as Array<CAROUSEL_POST>;
      const carouselPostIdx = state.carouselPosts.findIndex(
        (p) => p.id === carouselPostToEdit.id
      );
      newArr[carouselPostIdx] = carouselPostToEdit;
      state.carouselPosts = newArr;
    });
  },
});

export default carouselPostsSlice.reducer;
