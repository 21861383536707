import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { TECarousel, TECarouselItem } from "tw-elements-react";
import { getCarouselPosts } from "../../actions/carouselposts";
import { CAROUSEL_POST } from "../../@types";

export default function Carousel(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const { carouselPosts } = useSelector(
    (state: RootState) => state.carouselposts
  );

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    dispatch(getCarouselPosts());
  }, [dispatch]);

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % carouselPosts.length);
  };

  const prevSlide = () => {
    setActiveIndex(
      (prevIndex) =>
        (prevIndex - 1 + carouselPosts.length) % carouselPosts.length
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Auto-slide every 5 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [carouselPosts]);

  if (!carouselPosts || !Array.isArray(carouselPosts)) {
    return <div>Loading...</div>;
  }

  const validPosts = carouselPosts.filter((post) => post != null);

  return (
    <div className="flex justify-center items-center">
      <TECarousel showControls showIndicators ride="carousel">
        <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
          {validPosts.map((post: CAROUSEL_POST, index: number) => (
            <TECarouselItem
              key={index}
              itemID={index}
              className={`relative float-left w-full transition-transform duration-600 ease-in-out ${
                index === activeIndex ? "block" : "hidden"
              }`}
            >
              <img
                src={post.carousel_post_image}
                className="block w-full max-w-full max-h-96 object-contain mx-auto"
                alt={`Slide ${index + 1}`}
              />
              <div className="absolute inset-x-0 bottom-5 flex justify-center py-5">
                <div className="bg-white bg-opacity-75 px-4 py-2 rounded-lg text-center text-black">
                  <h5 className="text-xl">{post.carousel_post_title}</h5>
                </div>
              </div>
            </TECarouselItem>
          ))}
        </div>
        <button
          type="button"
          className="absolute top-0 bottom-0 left-0 z-10 flex items-center justify-center p-0 text-center border-0 hover:outline-none focus:outline-none"
          onClick={prevSlide}
        >
          <span
            className="inline-block bg-no-repeat carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </button>
        <button
          type="button"
          className="absolute top-0 bottom-0 right-0 z-10 flex items-center justify-center p-0 text-center border-0 hover:outline-none focus:outline-none"
          onClick={nextSlide}
        >
          <span
            className="inline-block bg-no-repeat carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </button>
      </TECarousel>
    </div>
  );
}
