import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { createNewsPost } from "../../actions/news";
import AWS from "aws-sdk";
import Navbar from "../../components/NavBar";

import headerImg from "../../assets/Images/sda_header_logo-removebg-preview.png";
import { useNavigate } from "react-router-dom";

import SnackbarComponent, { SnackbarMessage } from "../../components/Snackbar";

const initialFormData = {
  news_title: "",
  news_publish_date: "",
  news_body: "",
  news_image: null as File | null,
  news_to_display: false,
};

// Configure AWS SDK
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3();

const CreateNewsPostForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);

  const dispatch = useDispatch<AppDispatch>();
  const { status, error } = useSelector((state: RootState) => state.news);

  const [snackbarMessage, setSnackbarMessage] =
    useState<SnackbarMessage | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files, checked } = e.target;
    if (name === "news_image" && files) {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const uploadImageToS3 = async (file: File) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME!,
      Key: `news_image/${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
    };

    try {
      const { Location } = await s3.upload(params).promise();
      return Location;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let imageUrl = "";

    if (formData.news_image) {
      try {
        imageUrl = await uploadImageToS3(formData.news_image);
      } catch (err) {
        console.error("Error uploading image: ", err);
        return;
      }
    }

    const formattedData = {
      ...formData,
      news_publish_date: new Date(formData.news_publish_date)
        .toISOString()
        .split("T")[0],
      news_image: imageUrl,
    };

    // Dispatch the action to create a news post
    dispatch(createNewsPost(formattedData)).then((response) => {
      if (response.type.includes("fulfilled")) {
        setSnackbarMessage({
          message: "Carousel post created successfully!",
          severity: "success",
        });
        setFormData(initialFormData); // Clear the form after successful submission
      } else {
        setSnackbarMessage({
          message: "Error creating carousel post",
          severity: "error",
        });
      }
    });
  };

  return (
    <div>
      <img
        src={headerImg}
        className="w-52"
        alt="Header Logo"
        onClick={() => navigate(`/`)}
      />
      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg space-y-4"
      >
        <h2 className="text-2xl font-semibold mb-4">Create News Post</h2>
        <div>
          <label className="block text-gray-700">News Title</label>
          <input
            type="text"
            name="news_title"
            value={formData.news_title}
            onChange={handleChange}
            required
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Publish Date</label>
          <input
            type="date"
            name="news_publish_date"
            value={formData.news_publish_date}
            onChange={handleChange}
            required
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700">Body</label>
          <input
            name="news_body"
            value={formData.news_body}
            onChange={handleChange}
            required
            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          ></input>
        </div>
        <div>
          <label className="block text-gray-700">Image</label>
          <input
            type="file"
            name="news_image"
            onChange={handleChange}
            className="mt-1 block w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
          />
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="news_to_display"
            checked={formData.news_to_display}
            onChange={handleChange}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label className="ml-2 block text-gray-700">To Display</label>
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Create News Post
        </button>
        {status === "pending" && (
          <p className="text-yellow-500">Submitting...</p>
        )}
        {status === "failed" && (
          <p className="text-red-500">
            Error: {typeof error === "object" ? JSON.stringify(error) : error}
          </p>
        )}
      </form>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />
    </div>
  );
};

export default CreateNewsPostForm;
