import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  clearEventPosts,
  createEventPost,
  deleteEventPost,
  editEventPost,
  getEventPosts,
  getEventPost,
} from "../actions/events";
import { EVENT_POST } from "../@types";

interface EVENT_POSTS_INITIAL_STATE {
  eventPosts: Array<EVENT_POST>;
  status: "idle" | "pending" | "succeeded" | "failed";
  error: any;
}

const initialState: EVENT_POSTS_INITIAL_STATE = {
  eventPosts: [],
  status: "idle",
  error: null,
};

const eventPostsSlice = createSlice({
  name: "eventPosts",
  initialState,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<EVENT_POSTS_INITIAL_STATE>
  ) => {
    builder.addCase(getEventPosts.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action,
    }));
    builder.addCase(getEventPosts.fulfilled, (_state, action) => ({
      eventPosts: action.payload,
      error: null,
      status: "succeeded",
    }));
    builder.addCase(createEventPost.fulfilled, (state, action) => {
      const { eventPost } = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.eventPosts));
      newArr.push(eventPost);
    });
    builder.addCase(createEventPost.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(deleteEventPost.fulfilled, (state, action) => {
      const eventPostId = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.eventPosts));
      const eventPostIdx = state.eventPosts.find((p) => p.id === eventPostId);
      newArr.splice(eventPostIdx, 1);
      state.eventPosts = newArr;
    });
    builder.addCase(clearEventPosts.fulfilled, () => ({ ...initialState }));
    builder.addCase(editEventPost.fulfilled, (state, action) => {
      const eventPostToEdit: EVENT_POST =
        action.payload as unknown as EVENT_POST;
      const newArr = JSON.parse(
        JSON.stringify(state.eventPosts)
      ) as unknown as Array<EVENT_POST>;
      const eventPostIdx = state.eventPosts.findIndex(
        (p) => p.id === eventPostToEdit.id
      );
      newArr[eventPostIdx] = eventPostToEdit;
      state.eventPosts = newArr;
    });
  },
});

export default eventPostsSlice.reducer;
