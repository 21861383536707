import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getNewsPosts } from "../../actions/news";
import { getEventPosts } from "../../actions/events";

import newsEvntImg from "../../assets/Images/event_banner.png";
import newsletterImg from "../../assets/Images/newsletter_banner.png";

import Navbar from "../../components/NavBar";
import NewsPostCard from "../../components/NewsPostsCard";
import EventPostCard from "../../components/EventsPostsCard";
import Footer from "../../components/Footer";

const NewsAndEventsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { newsPosts, status, error } = useAppSelector((state) => state.news);
  const { eventPosts } = useAppSelector((state) => state.events);

  useEffect(() => {
    dispatch(getNewsPosts());
    dispatch(getEventPosts());
  }, [dispatch]);

  return (
    <div className="bg-[#FAF5EF] flex flex-col min-h-screen">
      <Navbar />
      <img
        src={newsEvntImg}
        alt="news and events banner"
        className="w-full h-32 md:h-48 rounded-lg p-2 object-cover"
      />
      <div className="flex justify-center items-center px-4 md:px-0">
        <img
          src={newsletterImg}
          alt="newsletter banner"
          className="w-full md:w-auto rounded-lg py-6 object-cover"
        />
      </div>
      <div className="flex flex-col lg:flex-row justify-between px-4 md:px-8 lg:px-16 space-y-8 lg:space-y-0 lg:space-x-8">
        {/* News Section */}
        <div className="flex flex-col w-full lg:w-1/2">
          <div className="text-2xl md:text-3xl font-bold font-oswald mb-4 text-gray-800">
            Latest News
          </div>
          <div className="space-y-6">
            {newsPosts
              .filter((newss) => newss !== null)
              .map((newss) => (
                <NewsPostCard key={newss.id} news={newss} />
              ))}
          </div>
        </div>
        {/* Events Section */}
        <div className="flex flex-col w-full lg:w-1/2">
          <div className="text-2xl md:text-3xl font-bold font-oswald mb-4 text-gray-800">
            Latest Events
          </div>
          <div className="space-y-6">
            {eventPosts
              .filter((event) => event !== null)
              .map((event) => (
                <EventPostCard key={event.id} events={event} />
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsAndEventsPage;
