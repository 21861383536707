import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../constants/api";

interface Credentials {
  username: string;
  password: string;
}

export const login = createAsyncThunk(
  "auth/login",
  async (credentials: Credentials, { rejectWithValue }) => {
    try {
      const response = await api.post("/auth/api/token/", credentials);
      const { access_token, refresh_token } = response.data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk<void>("auth/logout", async () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
});
