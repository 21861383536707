import React from "react";

const PhotoCollage: React.FC = () => {
  const images = [
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/14DFC384-EE0E-4B0E-BE3A-31A46536CB3C.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_3322.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_0387.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/DSC08836.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/DSCF2581.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/9ADE3315-EAEB-47CF-B47A-B84D987BCA47.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_2707.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_2600.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/20240110_224028_Original.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/IMG_2203.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/DSC00408.png",
    "https://sda-asset-images.s3.us-west-1.amazonaws.com/homepage_carousel/345214.png",
  ];

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {Array.from({ length: 4 }).map((_, colIndex) => (
        <div key={colIndex} className="grid gap-4">
          {images.slice(colIndex * 3, colIndex * 3 + 3).map((src, imgIndex) => (
            <div key={imgIndex}>
              <img className="h-auto max-w-full rounded-lg" src={src} alt="" />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PhotoCollage;
