import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";

import { getNewsPost } from "../../actions/news";

import noImgAvail from "../../assets/Images/noImgAvail.png";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

const NewsPostPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { newsPosts, status, error } = useAppSelector((state) => state.news);
  const newsPost = newsPosts.find((newss) => String(newss.id) === id);

  useEffect(() => {
    if (id) {
      console.log("Dispatching getNewsPost for ID:", id);
      dispatch(getNewsPost(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    console.log("Event posts:", newsPosts); // Debug log
  }, [newsPosts]);

  if (status === "pending") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  if (!newsPost) {
    return <div>No event found</div>;
  }

  const formatDate = (date: string | Date): string => {
    const d = typeof date === "string" ? new Date(date) : date;
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}-${day}-${year}`;
  };

  return (
    <div>
      <NavBar />
      <h1 className="text-center font-bold text-3xl">{newsPost.news_title}</h1>
      <div className="flex items-center justify-center mt-10 mb-10">
        <img
          className="w-96 "
          src={newsPost.news_image || noImgAvail}
          alt={newsPost.news_title}
        />
      </div>
      <h5 className="m-10 text-gray-600">
        Published On: {formatDate(newsPost.news_publish_date)}
      </h5>
      <h3 className="m-10 text-center">{newsPost.news_body}</h3>
      <Footer />
    </div>
  );
};

export default NewsPostPage;
