import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getMembers, deleteMember } from "../../actions/members";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditMemberForm from "../EditMemberForm";

const StyledTableCellHeader = styled(TableCell)(() => ({
  backgroundColor: "#F5F5F5",
  fontFamily: "Neue Haas Grotesk",
  fontWeight: "600",
  textAlign: "center",
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  fontWeight: "500",
  textAlign: "center",
  fontFamily: "Neue Haas Grotesk",
}));

const formatDate = (date: string | Date): string => {
  const d = typeof date === "string" ? new Date(date) : date;
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const year = d.getFullYear();
  return `${month}-${day}-${year}`;
};

const AdminMemberTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { members, status, error } = useAppSelector((state) => state.members);
  const [editMemberId, setEditMemberId] = useState<number | string | null>(
    null
  );

  useEffect(() => {
    dispatch(getMembers());
  }, [dispatch]);

  const handleDelete = (id: number | string) => {
    if (window.confirm("Are you sure you want to delete this member?")) {
      dispatch(deleteMember(id));
    }
  };

  const handleEdit = (id: number | string) => {
    setEditMemberId(id);
  };

  if (status === "pending") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    const errorMessage = error?.detail ? error.detail : JSON.stringify(error);
    return <div>Error: {errorMessage}</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Members Table">
        <TableHead>
          <TableRow>
            <StyledTableCellHeader>Member Name</StyledTableCellHeader>
            <StyledTableCellHeader>Email Address</StyledTableCellHeader>
            <StyledTableCellHeader>DOB</StyledTableCellHeader>
            <StyledTableCellHeader>Member Chapter</StyledTableCellHeader>
            <StyledTableCellHeader>Member Class</StyledTableCellHeader>
            <StyledTableCellHeader>Member Status</StyledTableCellHeader>
            <StyledTableCellHeader>NB Active Member</StyledTableCellHeader>
            <StyledTableCellHeader>Omega Status</StyledTableCellHeader>
            <StyledTableCellHeader>OG Founding Father</StyledTableCellHeader>
            <StyledTableCellHeader>Actions</StyledTableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {members &&
            members.map(
              (member: any) =>
                member && (
                  <TableRow key={member.id}>
                    <StyledTableCell>{member.full_name}</StyledTableCell>
                    <StyledTableCell>{member.email}</StyledTableCell>
                    <StyledTableCell>
                      {formatDate(member.date_of_birth)}
                    </StyledTableCell>
                    <StyledTableCell>{member.chapter}</StyledTableCell>
                    <StyledTableCell>{member.member_class}</StyledTableCell>
                    <StyledTableCell>{member.member_status}</StyledTableCell>
                    <StyledTableCell>
                      {member.national_board_active_member ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {member.omega_status ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {member.og_founding_father ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={() => handleEdit(member.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(member.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                )
            )}
        </TableBody>
      </Table>
      {editMemberId && (
        <EditMemberForm
          memberId={editMemberId}
          onClose={() => setEditMemberId(null)}
        />
      )}
    </TableContainer>
  );
};

export default AdminMemberTable;
