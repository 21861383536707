import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  clearMembers,
  createMember,
  deleteMember,
  editMember,
  getMembers,
} from "../actions/members";
import { MEMBER } from "../@types";

interface MEMBERS_INITIAL_STATE {
  members: Array<MEMBER>;
  status: "idle" | "pending" | "succeeded" | "failed";
  error: any;
}

const initialState: MEMBERS_INITIAL_STATE = {
  members: [],
  status: "idle",
  error: null,
};

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {},

  extraReducers(builder: ActionReducerMapBuilder<MEMBERS_INITIAL_STATE>) {
    builder.addCase(getMembers.rejected, (_state, action) => ({
      ...initialState,
      status: "failed",
      error: action,
    }));
    builder.addCase(getMembers.fulfilled, (_state, action) => ({
      members: action.payload,
      error: null,
      status: "succeeded",
    }));
    builder.addCase(createMember.fulfilled, (state, action) => {
      const { member } = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.members));
      newArr.push(member);
      state.members = newArr;
    });
    builder.addCase(createMember.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload; // Store the error message in the state for further use
    });
    builder.addCase(deleteMember.fulfilled, (state, action) => {
      const memberId = action.payload;
      const newArr = JSON.parse(JSON.stringify(state.members));
      const memberIdx = state.members.find((p) => p.id === memberId);
      newArr.splice(memberIdx, 1);
      state.members = newArr;
    });
    builder.addCase(clearMembers.fulfilled, () => ({ ...initialState }));
    builder.addCase(editMember.fulfilled, (state, action) => {
      const memberToEdit: MEMBER = action.payload as unknown as MEMBER;
      const newArr = JSON.parse(
        JSON.stringify(state.members)
      ) as unknown as Array<MEMBER>;
      const memberIdx = state.members.findIndex(
        (p) => p.id === memberToEdit.id
      );
      newArr[memberIdx] = memberToEdit;
      state.members = newArr;
    });
  },
});

export default membersSlice.reducer;
