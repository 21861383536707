import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { editNbMember, getNbMember } from "../../actions/nbmembers";
import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  Grid,
} from "@mui/material";

interface EditNBMemberFormProps {
  nbMemberId: number | string;
  onClose: () => void;
}

const EditNBMemberForm: React.FC<EditNBMemberFormProps> = ({
  nbMemberId,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { nbMembers } = useSelector((state: RootState) => state.nbmembers);
  const nbMember = nbMembers.find((member) => member.id === nbMemberId);

  const [formData, setFormData] = useState({
    member_name: "",
    position_name: "",
    email_address: "",
    chapter_member: "",
    class_member: "",
    member_pic: "",
  });

  useEffect(() => {
    if (nbMember) {
      setFormData({
        member_name: nbMember.member_name,
        position_name: nbMember.position_name,
        email_address: nbMember.email_address,
        chapter_member: nbMember.chapter_member,
        class_member: nbMember.class_member,
        member_pic: nbMember.member_pic,
      });
    }
  }, [nbMember]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (nbMember) {
      dispatch(editNbMember({ ...formData, id: nbMemberId })).then(() => {
        onClose();
      });
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Edit NB Member
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="member_name"
                label="Member Name"
                value={formData.member_name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="position_name"
                label="Position Name"
                value={formData.position_name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email_address"
                label="Email Address"
                value={formData.email_address}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="chapter_member"
                label="Chapter Member"
                value={formData.chapter_member}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="class_member"
                label="Class Member"
                value={formData.class_member}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="member_pic"
                label="Member Picture URL"
                value={formData.member_pic}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button type="submit" variant="contained" color="primary">
              Save Changes
            </Button>
            <Button variant="contained" color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default EditNBMemberForm;
