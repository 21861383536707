import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getEventPosts, deleteEventPost } from "../../actions/events";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditEventPostForm from "../EditEventPostForm";

const StyledTableCellHeader = styled(TableCell)(() => ({
  backgroundColor: "#F5F5F5",
  fontFamily: "Neue Haas Grotesk",
  fontWeight: "600",
  textAlign: "center",
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  fontWeight: "500",
  textAlign: "center",
  fontFamily: "Neue Haas Grotesk",
}));

const formatDate = (date: string | Date): string => {
  if (!date) return "N/A";
  const d = typeof date === "string" ? new Date(date) : date;
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const year = d.getFullYear();
  return `${month}-${day}-${year}`;
};

const AdminEventPostTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { eventPosts, status, error } = useAppSelector((state) => state.events);
  const [editEventId, setEditEventId] = useState<number | string | null>(null);

  useEffect(() => {
    dispatch(getEventPosts());
  }, [dispatch]);

  const handleDelete = (id: number | string) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      dispatch(deleteEventPost(id));
    }
  };

  const handleEdit = (id: number | string) => {
    setEditEventId(id);
  };

  if (status === "pending") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    const errorMessage = error?.detail ? error.detail : error;
    return <div>Error: {errorMessage}</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="events table">
        <TableHead>
          <TableRow>
            <StyledTableCellHeader>Event Title</StyledTableCellHeader>
            <StyledTableCellHeader>Start Date</StyledTableCellHeader>
            <StyledTableCellHeader>End Date</StyledTableCellHeader>
            <StyledTableCellHeader>Event Body</StyledTableCellHeader>
            <StyledTableCellHeader>Event Image</StyledTableCellHeader>
            <StyledTableCellHeader>Display Event</StyledTableCellHeader>
            <StyledTableCellHeader>Actions</StyledTableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventPosts &&
            eventPosts.map((event) =>
              event ? (
                <TableRow key={event?.id}>
                  <StyledTableCell>
                    {event?.event_title || "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {event?.event_start_date
                      ? formatDate(event.event_start_date)
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {event?.event_end_date
                      ? formatDate(event.event_end_date)
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {event?.event_body || "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {event?.event_image || "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {event?.event_to_display ? "Yes" : "No"}
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={() => handleEdit(event.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(event.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ) : (
                <TableRow key={Math.random()}>
                  <StyledTableCell colSpan={6}>No Data</StyledTableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
      {editEventId && (
        <EditEventPostForm
          eventPostId={editEventId}
          onClose={() => setEditEventId(null)}
        />
      )}
    </TableContainer>
  );
};

export default AdminEventPostTable;
