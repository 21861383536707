import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { deleteNbMember, getNbMembers } from "../../actions/nbmembers";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNBMemberForm from "../EditNbMemberForm";

const StyledTableCellHeader = styled(TableCell)(() => ({
  backgroundColor: "#F5F5F5",
  fontFamily: "Neue Haas Grotesk",
  fontWeight: "600",
  textAlign: "center",
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  fontWeight: "500",
  textAlign: "center",
  fontFamily: "Neue Haas Grotesk",
}));

const AdminNbMemberTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { nbMembers, status, error } = useAppSelector(
    (state) => state.nbmembers
  );
  const [editNbMemberId, setEditNbMemberId] = useState<number | string | null>(
    null
  );

  useEffect(() => {
    dispatch(getNbMembers());
  }, [dispatch]);

  const handleDelete = (id: number | string) => {
    if (window.confirm("Are you sure you want to delete this NB Member?")) {
      dispatch(deleteNbMember(id));
    }
  };

  const handleEdit = (id: number | string) => {
    setEditNbMemberId(id);
  };

  if (status === "pending") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    const errorMessage = error?.detail ? error.detail : error;
    return <div>Error: {errorMessage}</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="nb members table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Member Image</StyledTableCell>
            <StyledTableCellHeader>Member Name</StyledTableCellHeader>
            <StyledTableCell>Position</StyledTableCell>
            <StyledTableCellHeader>Email Address</StyledTableCellHeader>
            <StyledTableCellHeader>Chapter</StyledTableCellHeader>
            <StyledTableCell>Class</StyledTableCell>
            <StyledTableCellHeader>Actions</StyledTableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {nbMembers &&
            nbMembers.map((nbMember) =>
              nbMember && nbMember.member_name ? (
                <TableRow key={nbMember.id}>
                  <StyledTableCell>{nbMember.member_pic}</StyledTableCell>
                  <StyledTableCell>{nbMember.member_name}</StyledTableCell>
                  <StyledTableCell>{nbMember.position_name}</StyledTableCell>
                  <StyledTableCell>{nbMember.email_address}</StyledTableCell>
                  <StyledTableCell>{nbMember.chapter_member}</StyledTableCell>
                  <StyledTableCell>{nbMember.class_member}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={() => handleEdit(nbMember.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(nbMember.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ) : (
                <TableRow key={nbMember?.id || Math.random()}>
                  <StyledTableCell colSpan={4}>
                    Invalid Chapter Data
                  </StyledTableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
      {editNbMemberId && (
        <EditNBMemberForm
          nbMemberId={editNbMemberId}
          onClose={() => setEditNbMemberId(null)}
        />
      )}
    </TableContainer>
  );
};

export default AdminNbMemberTable;
