import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getChapters, deleteChapter } from "../../actions/chapters";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditChapterForm from "../EditChapterForm";
// Adjust the import path as necessary

const StyledTableCellHeader = styled(TableCell)(() => ({
  backgroundColor: "#F5F5F5",
  fontFamily: "Neue Haas Grotesk",
  fontWeight: "600",
  textAlign: "center",
}));

const StyledTableCell = styled(TableCell)(() => ({
  border: "none",
  fontWeight: "500",
  textAlign: "center",
  fontFamily: "Neue Haas Grotesk",
}));

const formatDate = (date: string | Date): string => {
  const d = typeof date === "string" ? new Date(date) : date;
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const year = d.getFullYear();
  return `${month}-${day}-${year}`;
};

const AdminChapterTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { chapters, status, error } = useAppSelector((state) => state.chapters);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentChapterId, setCurrentChapterId] = useState<
    number | string | null
  >(null);

  useEffect(() => {
    dispatch(getChapters());
  }, [dispatch]);

  const handleDelete = (id: number | string) => {
    if (window.confirm("Are you sure you want to delete this chapter?")) {
      dispatch(deleteChapter(id));
    }
  };

  const handleEditOpen = (id: number | string) => {
    setCurrentChapterId(id);
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setCurrentChapterId(null);
  };

  if (status === "pending") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    const errorMessage = error?.detail ? error.detail : error;
    return <div>Error: {errorMessage}</div>;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="chapters table">
          <TableHead>
            <TableRow>
              <StyledTableCell>School Logo</StyledTableCell>
              <StyledTableCellHeader>School Name</StyledTableCellHeader>
              <StyledTableCellHeader>Chapter</StyledTableCellHeader>
              <StyledTableCellHeader>Founding Date</StyledTableCellHeader>
              <StyledTableCell>School Status</StyledTableCell>
              <StyledTableCell>School Website</StyledTableCell>
              <StyledTableCellHeader>Actions</StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {chapters &&
              chapters.map((chapter) =>
                chapter && chapter.chapter_name ? (
                  <TableRow key={chapter.id}>
                    <StyledTableCell>{chapter.school_logo}</StyledTableCell>
                    <StyledTableCell>{chapter.chapter_name}</StyledTableCell>
                    <StyledTableCell>{chapter.chapter_sign}</StyledTableCell>
                    <StyledTableCell>
                      {formatDate(chapter.chapter_founding_date)}
                    </StyledTableCell>
                    <StyledTableCell>{chapter.chapter_status}</StyledTableCell>
                    <StyledTableCell>{chapter.school_website}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={() => handleEditOpen(chapter.id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(chapter.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ) : (
                  <TableRow key={chapter?.id || Math.random()}>
                    <StyledTableCell colSpan={4}>
                      Invalid Chapter Data
                    </StyledTableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={editDialogOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Chapter</DialogTitle>
        <DialogContent>
          {currentChapterId && (
            <EditChapterForm
              chapterId={currentChapterId}
              onClose={handleEditClose}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminChapterTable;
