import React from "react";
import Carousel from "../../components/Carousel";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

import homePgBnnr from "../../assets/Images/banner_beta.png";
import bodyImg from "../../assets/Images/Og-members.png";
import PhotoCollage from "../../components/PhotoCollage";

export default function Home() {
  return (
    <div className="bg-[#FAF5EF] flex flex-col min-h-screen">
      <NavBar />
      <Carousel />
      {/* Banner, Image, and body card */}
      <div className="mt-10 md:mt-20 space-y-6 md:space-y-10 px-4 md:px-6">
        <img
          src={homePgBnnr}
          className="w-full rounded-lg shadow-md"
          alt="Homepage Banner"
        />
        {/* Image and body card */}
        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 items-center">
          <img
            src={bodyImg}
            className="w-full md:w-1/2 lg:w-1/3 rounded-lg shadow-md"
            alt="Body Image"
          />
          <div className="w-full font-merriweather space-y-6 text-base md:text-lg md:w-1/2">
            <p className="text-gray-700">
              The first interest group was formed in the Fall semester of 1991,
              at San Jose State University. Future Founding Father, Francisco
              Huerta, had the idea of creating a new organization on campus to
              serve the needs of Latinos. Francisco Huerta took the forefront in
              organizing the initial meetings that set the groundwork for the
              establishment of Sigma Delta Alpha.
            </p>
            <p className="text-gray-700">
              The first introductory meeting held in November 1991. The
              fraternity's ideological Purposes were set and after several
              meetings and numerous successful events it was decided that it was
              time for these individuals to present themselves to the community
              as an established organization. On May 5, 1992, Sigma Delta Alpha
              was officially recognized with 18 Founding Fathers.
            </p>
            <p className="text-gray-700">
              This was one of only two Latino fraternities founded in the United
              States during the so-called fragmentation period from 1990 to
              2000, and the third to ever be founded in California.
            </p>
            <div className="flex justify-center items-center mt-4">
              <a
                href="/our-story"
                className="text-white bg-black py-2 px-4 rounded-lg shadow-md hover:bg-gray-800"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* News and Media Card */}
      <div className="mt-10 md:mt-20 px-4 md:px-6 py-8 md:py-12 bg-white rounded-lg shadow-md">
        <h2 className="text-xl md:text-2xl text-center font-oswald font-bold mb-6 text-gray-800">
          What does Brotherhood look like?
        </h2>
        <PhotoCollage />
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
}
