import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { Link } from "react-router-dom";

interface AdminNavBarProps {
  onSignOut?: () => void;
}

const AdminNavBar: React.FC<AdminNavBarProps> = ({ onSignOut }) => {
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(logout() as any); // TypeScript workaround
    if (onSignOut) {
      onSignOut();
    }
  };

  return (
    <div className="w-64 h-screen bg-black font-oswald text-white flex flex-col justify-between">
      <div className="mt-8">
        <Link to="/admin/manage-members">
          <div className="flex items-center p-4 cursor-pointer">
            <span className="mr-2">👥</span>
            <span>Manage Members</span>
          </div>
        </Link>
        <Link to="/admin/manage-chapters">
          <div className="flex items-center p-4 cursor-pointer">
            <span className="mr-2">🏢</span>
            <span>Manage Chapters</span>
          </div>
        </Link>
        <Link to="/admin/manage-events">
          <div className="flex items-center p-4 cursor-pointer">
            <span className="mr-2">📅</span>
            <span>Manage Events</span>
          </div>
        </Link>
        <Link to="/admin/manage-news">
          <div className="flex items-center p-4 cursor-pointer">
            <span className="mr-2">📰</span>
            <span>Manage News</span>
          </div>
        </Link>
        <Link to="/admin/manage-nb-members">
          <div className="flex items-center p-4 cursor-pointer">
            <span className="mr-2">📰</span>
            <span>Manage NB Members</span>
          </div>
        </Link>
        <Link to="/admin/manage-carousel-posts">
          <div className="flex items-center p-4 cursor-pointer">
            <span className="mr-2">📰</span>
            <span>Manage Carousel Posts</span>
          </div>
        </Link>
      </div>
      <div className="p-4">
        <button
          className="w-full bg-red-700 hover:bg-red-800 text-white py-2 rounded"
          onClick={handleSignOut}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default AdminNavBar;
