import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";

import { editCarouselPost, getCarouselPost } from "../../actions/carouselposts";
import { CAROUSEL_POST, CREATE_CAROUSEL_POST_FORM } from "../../@types";

interface EditCarouselPostFormProps {
  carouselPostId: string | number;
  onClose: () => void;
}

const EditCarouselPostForm: React.FC<EditCarouselPostFormProps> = ({
  carouselPostId,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { carouselPosts } = useSelector(
    (state: RootState) => state.carouselposts
  );

  const [formData, setFormData] = useState<
    CREATE_CAROUSEL_POST_FORM & { id: number | string }
  >({
    id: carouselPostId,
    carousel_post_title: "",
    carousel_post_start_date: "",
    carousel_post_end_date: "",
    carousel_post_body: "",
    carousel_post_image: "",
    carousel_postt_to_display: false,
  });

  useEffect(() => {
    dispatch(getCarouselPost(carouselPostId));
  }, [dispatch, carouselPostId]);

  useEffect(() => {
    const currentCarouselPost = carouselPosts.find(
      (carousel: CAROUSEL_POST) => carousel.id === carouselPostId
    );
    if (currentCarouselPost) {
      setFormData({
        id: carouselPostId,
        carousel_post_title: currentCarouselPost.carousel_post_title || "",
        carousel_post_start_date:
          currentCarouselPost.carousel_post_start_date || "",
        carousel_post_end_date:
          currentCarouselPost.carousel_post_end_date || "",
        carousel_post_body: currentCarouselPost.carousel_post_body || "",
        carousel_post_image: currentCarouselPost.carousel_post_image || "",
        carousel_postt_to_display:
          currentCarouselPost.carousel_postt_to_display || false,
      });
    }
  }, [carouselPosts, carouselPostId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.carousel_post_title) {
      dispatch(editCarouselPost(formData));
      onClose();
    } else {
      alert("Please fill in all required fields.");
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <form onSubmit={handleSubmit} className="space-y-6">
        <h2 className="text-2xl font-bold mb-4">Edit Carousel Post</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              name="carousel_post_title"
              value={formData.carousel_post_title}
              onChange={handleChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Start Date
            </label>
            <input
              type="date"
              name="carousel_post_start_date"
              value={formData.carousel_post_start_date.toString()}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              End Date
            </label>
            <input
              type="date"
              name="carousel_post_end_date"
              value={formData.carousel_post_end_date.toString()}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Body
            </label>
            <input
              name="carousel_post_body"
              value={formData.carousel_post_body}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Image URL
            </label>
            <input
              type="text"
              name="carousel_post_image"
              value={formData.carousel_post_image}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="carousel_postt_to_display"
              checked={formData.carousel_postt_to_display}
              onChange={handleChange}
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label className="ml-2 block text-sm font-medium text-gray-700">
              Display Post
            </label>
          </div>
        </div>
        <div className="flex space-x-4">
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
          <button
            type="button"
            onClick={onClose}
            className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCarouselPostForm;
