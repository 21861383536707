import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface SnackbarMessage {
  message: string;
  severity: "success" | "error" | "warning" | "info";
}

interface SnackbarComponentProps {
  snackbarMessage: SnackbarMessage | null;
  onClose: () => void;
}

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({
  snackbarMessage,
  onClose,
}) => {
  const [open, setOpen] = useState(!!snackbarMessage);

  const handleClose = (
    event: React.SyntheticEvent<Element, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    setOpen(!!snackbarMessage);
  }, [snackbarMessage]);

  return (
    <>
      {snackbarMessage && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={snackbarMessage.severity}>
            {snackbarMessage.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackbarComponent;
