import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getEventPost } from "../../actions/events";
import noImgAvail from "../../assets/Images/noImgAvail.png";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";

const EventsPostPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { eventPosts, status, error } = useAppSelector((state) => state.events);
  const eventPost = eventPosts.find((event) => String(event.id) === id);

  useEffect(() => {
    if (id) {
      console.log("Dispatching getEventPost for ID:", id); // Debug log
      dispatch(getEventPost(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    console.log("Event posts:", eventPosts); // Debug log
  }, [eventPosts]);

  if (status === "pending") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  if (!eventPost) {
    return <div>No event found</div>;
  }

  const formatDate = (date: string | Date): string => {
    const d = typeof date === "string" ? new Date(date) : date;
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}-${day}-${year}`;
  };

  return (
    <div className="flex flex-col">
      <Navbar />
      <h1 className="text-center font-bold text-3xl">
        {eventPost.event_title}
      </h1>
      <div className="flex flex-row items-center justify-center text-2xl tracking-wider">
        <h2>{formatDate(eventPost.event_start_date)}</h2>
        <h2> / </h2>
        <h2>{formatDate(eventPost.event_end_date)}</h2>
      </div>
      <div className="flex justify-center items-center mt-10 mb-10">
        <img
          className="w-96"
          src={eventPost.event_image || noImgAvail}
          alt={eventPost.event_title}
        />
      </div>
      <h2 className="m-10 text-center">{eventPost.event_body}</h2>
      <Footer />
    </div>
  );
};

export default EventsPostPage;
