import axios, { Axios, AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import apiUrl from "../constants/apiUrl";
import { CREATE_NEWS_POST_FORM, NEWS_POST } from "../@types";
import api from "../constants/api";

const baseApiUrl = `${apiUrl}/news-post/`;

export const GET_NEWS_POSTS = "GET_NEWS_POSTS";
export const getNewsPosts = createAsyncThunk(GET_NEWS_POSTS, async () => {
  const res: AxiosResponse = await axios.get(baseApiUrl);
  return res.data;
});

export const CREATE_NEWS_POST = "CREATE_NEWS_POST";
export const createNewsPost = createAsyncThunk(
  CREATE_NEWS_POST,
  async (newsPostData: CREATE_NEWS_POST_FORM, { rejectWithValue }) => {
    try {
      const res: AxiosResponse = await api.post(baseApiUrl, newsPostData);
      return res.data;
    } catch (err: any) {
      // Return the error using rejectWithValue
      return rejectWithValue(err.response.data);
    }
  }
);

export const DELETE_NEWS_POST = "DELETE_NEWS_POST";
export const deleteNewsPost = createAsyncThunk(
  DELETE_NEWS_POST,
  async (NewsPostId: number | string) => {
    try {
      await api.delete(`${baseApiUrl}${NewsPostId}/`, {});
      return NewsPostId;
    } catch (err) {
      return false;
    }
  }
);

export const EDIT_NEWS_POST = "EDIT_NEWS_POST";
export const editNewsPost = createAsyncThunk(
  EDIT_NEWS_POST,
  async (newsPostToEdit: NEWS_POST) => {
    try {
      const { id, ...newsPostDataToSend } = newsPostToEdit;
      await api.put(`${baseApiUrl}${id}/`, newsPostDataToSend);
      return newsPostToEdit;
    } catch (err) {
      return null;
    }
  }
);

export const GET_NEWS_POST = "GET_NEWS_POST";
export const getNewsPost = createAsyncThunk(
  GET_NEWS_POST,
  async (id: string | number) => {
    const res: AxiosResponse = await axios.get(`${baseApiUrl}${id}/`);
    return res.data;
  }
);

export const clearNewsPosts = createAsyncThunk(
  "CLEAR_NEWS_POSTS",
  async () => null
);
