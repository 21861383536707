import api from "../constants/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CREATE_CHAPTER_FORM } from "../@types";
import axios, { AxiosResponse } from "axios";

const baseApiUrl = "sigmas/chapters/";
const deleteApiUrl = "sigmas/chapters";

// export const GET_CHAPTERS = "GET_CHAPTERS";
// export const getChapters = createAsyncThunk(GET_CHAPTERS, async () => {
//     const res = await api.get(baseApiUrl);
//     return res.data;
// });

// export const GET_CHAPTERS = "GET_CHAPTERS";
// export const getChapters = createAsyncThunk(GET_CHAPTERS, async () => {
//   // Create an Axios instance without Authorization header
//   const publicApi = axios.create({
//     baseURL: 'http://localhost:8000/sigmas/chapters/', // Your backend API URL
//   });

//   const res = await publicApi.get(baseApiUrl);
//   return res.data;
// });

const publicApi = axios.create({
  baseURL: "https://backend.sigmadeltaalpha.com", // Your backend API URL, the localhost is http://localhost:8000/, production is https://backend.sigmadeltaalpha.com
});

// Do not include Authorization header in the public API instance
publicApi.interceptors.request.use(
  (config) => {
    delete config.headers["Authorization"];
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const GET_CHAPTERS = "GET_CHAPTERS";
export const getChapters = createAsyncThunk(GET_CHAPTERS, async () => {
  const res = await publicApi.get(baseApiUrl);
  return res.data;
});

// export const GET_CHAPTERS = "GET_CHAPTERS"
// export const getChapters = createAsyncThunk(GET_CHAPTERS, async() => {
//     const res: AxiosResponse = await axios.get(baseApiUrl)
//     return res.data
// })

export const CREATE_CHAPTER = "CREATE_CHAPTER";
export const createChapter = createAsyncThunk(
  CREATE_CHAPTER,
  async (chapterData: CREATE_CHAPTER_FORM, { rejectWithValue }) => {
    try {
      const res = await api.post(`${baseApiUrl}`, chapterData);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const DELETE_CHAPTER = "DELETE_CHAPTER";
export const deleteChapter = createAsyncThunk(
  DELETE_CHAPTER,
  async (ChapterId: number | string) => {
    try {
      await api.delete(`${baseApiUrl}${ChapterId}/`, {});
      return ChapterId;
    } catch (err) {
      return false;
    }
  }
);

export const EDIT_CHAPTER = "EDIT_CHAPTER";
export const editChapter = createAsyncThunk(
  EDIT_CHAPTER,
  async (chapterToEdit: CREATE_CHAPTER_FORM & { id: number | string }) => {
    try {
      const { id, ...chapterDataToSend } = chapterToEdit;
      await api.put(`${baseApiUrl}${id}/`, chapterDataToSend);
      return chapterToEdit;
    } catch (err) {
      return null;
    }
  }
);

export const GET_CHAPTER = "GET_CHAPTER";
export const getChapter = createAsyncThunk(
  GET_CHAPTER,
  async (id: string | number) => {
    const res = await api.get(`${baseApiUrl}/${id}`);
    return res.data;
  }
);

export const clearChapters = createAsyncThunk(
  "CLEAR_CHAPTERS",
  async () => null
);
