import { combineReducers } from "@reduxjs/toolkit";
import chapters from "./chapters";
import members from "./members";
import events from "./events";
import news from "./news";
import nbmembers from "./nbmembers";
import carouselposts from "./carouselposts";

const rootReducer = combineReducers({
  chapters,
  members,
  events,
  news,
  nbmembers,
  carouselposts,
});

export default rootReducer;
