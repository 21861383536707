import React from "react";
import noImgAvail from "../../assets/Images/noImgAvail.png";

interface ChapterCardProps {
  chapter: {
    id: number | string;
    chapter_name: string;
    chapter_sign: string;
    chapter_founding_date: string | Date;
    chapter_status: string;
    school_website: string;
    school_logo: string;
  };
}

const ChapterCard: React.FC<ChapterCardProps> = ({ chapter }) => {
  const formatDate = (date: string | Date): string => {
    const d = typeof date === "string" ? new Date(date) : date;
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}-${day}-${year}`;
  };

  return (
    <div className="flex justify-center p-6">
      <div className="flex flex-col sm:flex-row max-w-5xl w-full p-6 border items-center gap-6 border-gray-200 rounded-lg bg-gray-900 shadow-lg">
        <img
          className="w-24 h-24 sm:w-40 sm:h-40 mb-4 sm:mb-0 rounded-full shadow-lg object-cover"
          src={chapter.school_logo || noImgAvail}
          alt="Chapter logo"
        />
        <div className="flex flex-col items-center sm:items-start text-center sm:text-left">
          <h5 className="text-2xl font-bold text-white mb-2">
            {chapter.chapter_name}
          </h5>
          <p className="text-gray-400 mb-1">
            <span className="font-semibold">Chapter:</span>{" "}
            {chapter.chapter_sign}
          </p>
          <p className="text-gray-400 mb-1">
            <span className="font-semibold">Founded:</span>{" "}
            {formatDate(chapter.chapter_founding_date)}
          </p>
          <p className="text-gray-400 mb-1">
            <span className="font-semibold">Status:</span>{" "}
            {chapter.chapter_status}
          </p>
          <a
            href={chapter.school_website}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 underline mt-2"
          >
            Visit Website
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChapterCard;
