import React from "react";
import noImgAvail from "../../assets/Images/noImgAvail.png";
import { Link } from "react-router-dom";

interface NationalBoardMemberCardProps {
  nbMember: {
    id: number | string;
    member_name: string;
    position_name: string;
    email_address: string;
    chapter_member: string;
    class_member: string;
    member_pic: string;
  };
}

const NationalBoardMemberCard: React.FC<NationalBoardMemberCardProps> = ({
  nbMember,
}) => {
  return (
    <div className="w-full max-w-sm bg-gray-100 border rounded-lg shadow-lg p-4 text-center flex flex-col items-center justify-center h-80">
      <img
        className="w-24 h-24 rounded-full mb-4"
        src={nbMember.member_pic || noImgAvail}
        alt={nbMember.member_name}
      />
      <div className="bg-gray-200 py-2 w-full">
        <h5 className="text-lg font-semibold">{nbMember.position_name}</h5>
      </div>
      <div className="bg-gray-300 py-2 w-full flex-1 flex flex-col justify-center items-center">
        <p className="text-md">{nbMember.member_name}</p>
        <p className="text-sm text-gray-700">{nbMember.email_address}</p>
        <p className="text-sm">{nbMember.chapter_member}</p>
        <p className="text-sm text-gray-700">{nbMember.class_member}</p>
      </div>
    </div>
  );
};

export default NationalBoardMemberCard;
